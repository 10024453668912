import React from 'react';
import { MDBBtn } from 'mdbreact';

import logo from '../assets/rlc.svg';
import '../css/App.css';

import ENTRIES from '../config/entries'

const App = () => <>
	<div className='App'>
		<img src={logo} className='App-background' alt=''/>

		{
			ENTRIES.map(entry =>
				<MDBBtn
					href={ 'http://'+entry.domain+'.research.iex.ec' }
					color='yellow'
					size='lg'
					className='w-50'
				>
					{ entry.title }
				</MDBBtn>
			)
		}
	</div>
</>

export default App;
